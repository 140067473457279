<template>
  <div>
    <b-table
      class="grades-table"
      striped
      hover
      :items="items"
      :fields="fields"
      responsive
    ></b-table>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  props: {
    student_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: null,
    };
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      studyUnits: names.STUDY_UNITS,
    }),
    fields() {
      return [
        {
          key: "matter_name",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ),
          sortable: true,
        },
        {
          key: "section_name",
          label: this.$getVisibleNames("teaching.section", false, "Sección"),
          sortable: true,
        },
        {
          key: "study_unit_name",
          label: "Unidad de Estudio",
          sortable: true,
        },
        {
          key: "study_unit_weighing",
          label: "Ponderación",
        },
        {
          key: "evaluation_name",
          label: "Evaluación",
          sortable: true,
        },
        {
          key: "evaluation_weighing",
          label: "Ponderación",
        },
        {
          key: "grade",
          label: "Nota",
        },
        {
          key: "evaluation_id",
          label: "id",
        },
      ];
    },
    student() {
      return this.users.find((x) => x.id == this.student_id);
    },
  },
  methods: {
    fetchData() {
      this.$restful.Get(`/common/test/`).then((response) => {
        this.items = response;
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.grades-table::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background: transparent;
}

.grades-table::-webkit-scrollbar-track {
  background: var(--primary-hover-color);
  border-radius: 15px;
}

.grades-table::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 20px;
  border: 1px solid rgb(114, 114, 114);
}
</style>